<template>
  <div class="pa-5">
    <div class="widthDocument mb-5">
      <v-row v-if="$isMobile()">
        <v-col cols="8">
          <v-text-field
            flat
            label="Cari Document"
            class="custom_field"
            background-color="#F1FBFC"
            solo
            @keydown="allFilter"
            v-model="find"
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            color="blue"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="170"
            bottom
            :nudge-bottom="55"
            elevation="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card
                class="pa-3 d-flex align-center"
                outlined
                v-bind="attrs"
                v-on="on"
                height="100%"
              >
                <v-icon>mdi-filter-outline</v-icon>
                <v-icon>mdi-menu-down</v-icon>
              </v-card>
            </template>
            <v-card class="pa-2 popup_radius" outlined elevation="0">
              <p class="color_txt">Filter</p>
              <v-radio-group
                dense
                color="info"
                hide-details="auto"
                v-model="role"
              >
                <v-radio label="Semua" value="kosong"></v-radio>
                <v-radio label="Paparan" value="Paparan"></v-radio>
                <v-radio
                  label="Peraturan"
                  value="Peraturan"
                ></v-radio>
                <v-radio
                  label="Pedoman"
                  value="Pedoman"
                ></v-radio>
              </v-radio-group>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="txt-capitalize"
                  color="#00b4cc"
                  dark
                  depressed
                  width="100px"
                  @click="filterRole()"
                >
                  done
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="8">
          <v-text-field
            flat
            label="Cari Document"
            class="custom_field"
            background-color="#F1FBFC"
            solo
            @keydown="allFilter"
            v-model="find"
            hide-details="auto"
            prepend-inner-icon="mdi-magnify"
            color="blue"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="170"
            bottom
            :nudge-bottom="55"
            elevation="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card
                class="pa-3 d-flex align-center"
                outlined
                v-bind="attrs"
                v-on="on"
                height="100%"
              >
                <v-icon>mdi-filter-outline</v-icon>
                <v-icon>mdi-menu-down</v-icon>
              </v-card>
            </template>
            <v-card class="pa-2 popup_radius" outlined elevation="0">
              <p class="color_txt">Filter</p>
              <v-radio-group
                dense
                color="info"
                hide-details="auto"
                v-model="role"
              >
                <v-radio label="Semua" value="kosong"></v-radio>
                <v-radio label="Paparan" value="Paparan"></v-radio>
                <v-radio
                  label="Peraturan"
                  value="Peraturan"
                ></v-radio>
                <v-radio
                  label="Pedoman"
                  value="Pedoman"
                ></v-radio>
              </v-radio-group>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="txt-capitalize"
                  color="#00b4cc"
                  dark
                  depressed
                  width="100px"
                  @click="filterRole()"
                >
                  done
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      
    </div>

    <v-row v-if="$isMobile()">
      <v-col cols="6" md="3" v-for="(item, idx) in mutatedUser" :key="idx">

        <v-card class="custom_card rounded-max pa-3 " height="200px">

          <div class="document-card" style="height: 100%">
            <div class="img-document">
              <img v-if="item.typefile == 'DOC'" src="../../../assets/img/archive.svg" alt="">
              <img v-if="item.typefile == 'PDF'" src="../../../assets/img/pdf.svg" alt="">
            </div>

            <div class="d-flex flex-column justify-space-between " style="height: 100%">
              <div>
                <h5>{{item.title}}</h5>
                <p>{{item.category}}</p>
              </div>
              <div>
                <v-btn
                  rounded
                  color="#00b4cc"
                  dark
                  class="text-capitalize"
                  depressed
                  :href="item.path"
                  target="_blank"
                >
                  <v-icon class="mr-2">mdi-cloud-download-outline</v-icon> unduh
                </v-btn>
              </div>
            </div>
            
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="3" v-for="(item, idx) in mutatedUser" :key="idx">

        <v-card class="custom_card rounded-max pa-3 " height="200px">

          <div class="document-card" style="height: 100%">
            <div class="img-document">
              <img v-if="item.typefile == 'DOC'" src="../../../assets/img/archive.svg" alt="">
              <img v-if="item.typefile == 'PDF'" src="../../../assets/img/pdf.svg" alt="">
            </div>

            <div class="d-flex flex-column justify-space-between " style="height: 100%">
              <div>
                <h3>{{item.title}}</h3>
                <p>{{item.category}}</p>
              </div>
              <div>
                <v-btn
                  rounded
                  color="#00b4cc"
                  dark
                  class="text-capitalize"
                  depressed
                  :href="item.path"
                  target="_blank"
                >
                  <v-icon class="mr-2">mdi-cloud-download-outline</v-icon> unduh
                </v-btn>
              </div>
            </div>
            
          </div>
        </v-card>
      </v-col>
    </v-row>

    


    <div class=" d-flex justify-center pa-3">
      <div class="d-flex align-center pa-2 round_paginate" style="background: white">
        <v-btn
          small
          min-width="10px"
          color="#00b4cc"
          dark
          class="pa-2 custom_shadow"
          @click="pagination('prev')"
          ><v-icon small>mdi-arrow-left</v-icon></v-btn
        >
        <div class="mx-3">
          <p class="ma-0 color_default">{{ page }} of {{ total_page }}</p>
        </div>
        <v-btn
          small
          min-width="10px"
          color="#00b4cc"
          dark
          class="pa-2 custom_shadow"
          @click="pagination('next')"
          ><v-icon small>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "publicDocument",
  computed: {
    ...mapState({
      all_document: (state) => state.document.all_document,
    }),
    mutatedUser() {
      let data = null;
      if (this.all_document) {
        data = this.all_document.data.data;
      }
      return data;
    },
  },
  data() {
    return {
      loading: false,
      dialogUpdate: false,
      dataUser: null,
      dataFile: null,
      role: "kosong",
      page: 1,
      limit: 8,
      find: "",
      menu: false,
      menuLimit: false,
      dialogDeleteUser: false,
      dialogDeleteFile: false,
      dialogCreate: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      headers: [
        {
          text: "Nama File",
          align: "start",
          value: "title",
        },
        {
          text: "Kategori",
          align: "start",
          value: "category",
        },
        { text: "Aksi", sortable: false, value: "actions" },
      ],
      isNext: "",
      isPrev: "",
      total_page: null,
    };
  },
  mounted() {
    this.allFilter();
    this.getAllDocument();
   
  },
  methods: {
    
    pagination(state) {
      console.log(state, 'asd');
      if (state == "next") {
        if (this.isNext) {
          this.page++;
          this.allFilter();
        }
      } else {
        if (this.isPrev) {
          this.page--;
          this.allFilter();
        }
      }
    },
    getAllDocument() {
      this.$store.dispatch('document/getDocument8')
    },
    allFilter(type) {
      this.loading = true;
      this.page = type == "limit" ? 1 : this.page;
      this.menu = false;
      let body = {
        role: this.role,
        find: this.find,
        limit: this.limit,
        page: this.page,
      };
      let data = {
        status: null,
        body: body,
      };
      this.$store
        .dispatch('document/filterAllinOne8', data)
        .then((data) => {
          this.loading = false;
          this.isNext = data.data.data.next_page_url;
          this.isPrev = data.data.data.prev_page_url;
          this.total_page = Math.ceil(data.data.data.total / this.limit);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    filterRole() {
      this.menu = false;
      let body = {
        kategori: this.role == 'kosong' ? '' : this.role,
      };
      let data = {
        status: null,
        body: body,
      };
      this.loading = true;
      this.$store
        .dispatch("document/filterRole8", data)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  }
};
</script>

<style scoped>
.document-card{
  position: relative;
}
.document-card .img-document{
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}
.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.widthDocument {
  width: 45%;
  padding-top: 20px;
}

@media only screen and (max-width: 600px) {
  .widthDocument {
    width: 100%;
  }
}
</style>